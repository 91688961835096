import styled from "styled-components";

export const bgColor = styled.div`
background: #181818;
height: 100vh;
width: 100vw;
z-index: -2;
margin:auto;
position: inherit;
`
export const Container = styled.div`
  width: 100%;
  display: flex;
  margin-top: 100px;
  justify-content: center;
  align-content: center;
  overflow: hidden;
  -ms-overflow-style: hidden;
  -webkit-overflow-scrolling: none;
  img {
    text-align: center;
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 800px){
    img {
      width: 300%;
    }
  }
`;
