import React from "react";
import SEO from "../components/seo";
import * as Styles from "../css/404Style";
import Header from "../components/header";
import Footer from "../components/footer";
import fourOfour from "../images/404_3.png";
const NotFoundPage = () => {

  return (
    <Styles.bgColor>
      <Header id="header" />
      <SEO title={"404: Not found"} />
      <Styles.Container>
        <img src={fourOfour} alt="404 page, wine glasses" />
      </Styles.Container>
      <Footer/>
    </Styles.bgColor>
  );
};

export default NotFoundPage;
